
  import {defineComponent} from 'vue'
  import {ElMessageBox, ElMessage} from 'element-plus'
  import infoStaff from './components/info-staff.vue'
  import advanceList from './components/advance-list.vue';
//   import detailStaffSalary from './components/detail-staff-salary.vue'
  export default defineComponent({
    components: {
      infoStaff,
      advanceList
    //   detailStaffSalary
    },
    data() {
      return {
        idCardNo: '',
      }
    },
    mounted() {
      if (this.$route.query?.idCardNo) {
        this.idCardNo = atob(this.$route.query.idCardNo as any)
      }
    },
    methods: {

    }
  })
  