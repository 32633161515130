import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "head flex flex-ai-c" }
const _hoisted_3 = { class: "fs-16 bold" }
const _hoisted_4 = { class: "fs-12" }
const _hoisted_5 = { class: "body mg-t-20" }
const _hoisted_6 = { class: "flex mg-b-12" }
const _hoisted_7 = { class: "w-200" }
const _hoisted_8 = { class: "bold" }
const _hoisted_9 = { class: "w-200" }
const _hoisted_10 = { class: "bold" }
const _hoisted_11 = { class: "flex-1" }
const _hoisted_12 = { class: "bold" }
const _hoisted_13 = { class: "flex" }
const _hoisted_14 = { class: "w-200" }
const _hoisted_15 = { class: "bold" }
const _hoisted_16 = { class: "w-200" }
const _hoisted_17 = { class: "bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_card, { shadow: "never" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.detail.serveCompanyName), 1),
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "fs-12 fc-lightgray mg-l-16" }, "覆盖时间：", -1)),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.startTime) + " - " + _toDisplayString(_ctx.endTime), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _cache[1] || (_cache[1] = _createElementVNode("span", { class: "fc-lightgray" }, "发薪单数：", -1)),
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.setDefault(_ctx.detail.payOrderCount)), 1)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _cache[3] || (_cache[3] = _createElementVNode("span", { class: "fc-lightgray" }, "发薪金额：", -1)),
              _createElementVNode("span", _hoisted_10, [
                _cache[2] || (_cache[2] = _createElementVNode("span", null, "¥", -1)),
                _createTextVNode(_toDisplayString(_ctx.setDefault(_ctx.detail.payTotalMoney)), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _cache[5] || (_cache[5] = _createElementVNode("span", { class: "fc-lightgray" }, "停发金额：", -1)),
              _createElementVNode("span", _hoisted_12, [
                _cache[4] || (_cache[4] = _createElementVNode("span", null, "¥", -1)),
                _createTextVNode(_toDisplayString(_ctx.setDefault(_ctx.detail.payStopTotalMoney)), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _cache[6] || (_cache[6] = _createElementVNode("span", { class: "fc-lightgray" }, "发薪人数：", -1)),
              _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.setDefault(_ctx.detail.payTotalCount)), 1)
            ]),
            _createElementVNode("div", _hoisted_16, [
              _cache[8] || (_cache[8] = _createElementVNode("span", { class: "fc-lightgray" }, "待领金额：", -1)),
              _createElementVNode("span", _hoisted_17, [
                _cache[7] || (_cache[7] = _createElementVNode("span", null, "¥", -1)),
                _createTextVNode(_toDisplayString(_ctx.setDefault(_ctx.detail.payUnclaimedTotalMoney)), 1)
              ])
            ]),
            _cache[9] || (_cache[9] = _createElementVNode("div", { class: "flex-1" }, null, -1))
          ])
        ])
      ]),
      _: 1
    })
  ]))
}