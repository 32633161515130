
import {defineComponent} from 'vue'

import { serverCompanyBillInfo } from '@/api/salary'

export default defineComponent({
  components: {},
  props: {
    serveCompanyId: {
      type: [String, Number],
      default: ''
    },
    startTime: {
      type: String,
      default: ''
    },
    endTime: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      detail: {}
    }
  },
  watch: {
    serveCompanyId: {
      handler(newval) {
        if (newval) {
          this.getData()
        }
      },
      immediate: true
    }
  },
  computed: {
    setDefault() {
      return (value: string) => {
        return value ?? '0'
      }
    }
  },
  methods: {
    getData() {
      serverCompanyBillInfo({
        serveCompanyId: this.serveCompanyId as any,
        startTime: this.startTime,
        endTime: this.endTime
      }).then((res: any) => {
        this.detail = res || {}
      })
    }
  }
})
