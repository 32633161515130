import request from './request'
// 员工账单
export const getEmployeeSalary = async (params: any) => {
  let res = await request.get('rest/pay/pay-personal-paid/get-employee-salary', {
    params: params
  })
  return res
}

// 员工账单
export const getEmployeeSalaryHead = async (params: any) => {
  let res = await request.get('rest/pay/pay-personal-paid/get-employee-salary_head', {
    params: params
  })
  return res
}
/**
 * 领薪记录
 *
 * idCardNo
 * string
 * 员工身份证
 * 必需
 *
 * queryMonth
 * string
 * 查询月份
 * 必需
 * 示例值:
 * 2023-10
 *
 * status
 * integer
 * 可选
 * 0:未领取,1:已领取,2:停止,4:领薪失败 (未传:默认查询已领)
 *
 * pageNum
 * integer
 * 分页
 * 可选
 *
 * pageSize
 * integer
 * 分页
 * 可选
 * */
export const getEmployeeSalaryRecord = async (params: any) => {
  let res = await request.get('rest/pay/pay-personal-paid/get-employee-salary-record', {
    params: params
  })
  return res
}

/**
 * 导出记录
 * queryMonth
 * string
 * 可选
 * 示例值:
 * 2023-10
 *
 * idCardNos
 * array[string]
 * 勾选的身份证集合
 * 可选
 *
 * */
export const exportEmployeeSalaryRecord = async (params: any) => {
  let res = await request.get('rest/pay/pay-personal-paid/export-employee-salary-record', {
    params: params
  })
  return res
}

/**
 * 发薪记录-已领
 *
 * */
export const listReceived = async (params: any) => {
  let res = await request.get('rest/pay/pay-payment-record/list-received', {
    params: params
  })
  return res
}

/**
 * 发薪记录-停止
 *
 * */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const listStop = async (params: any) => {
  let res = await request.get('rest/pay/pay-payment-record/list-stop', {
    params: params
  })
  return res
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const servercompanybillapi = async (params: any) => {
  return await request.get('/rest/pay/pay-payment-record/server-company-bill', {
    params: params
  })
}
/**
 * 导出名单
 *
 * */
export const exportRecord = async (params: any) => {
  return await request.get('rest/pay/pay-payment-record/export-record', {
    params: params
  })
}
export const exportBill = async (params: any) => {
  return await request.get('/pay-module-export/statistics-info-all', {
    params: params
  })
}
/**
 * 账单详情—对账详单
 *
 * */
export const billDetails = async (params: any) => {
  return await request.get('rest/pay/pay-payment-record/bill-details', {
    params: params
  })
}

/**
 * 账单详情—对账总和
 *
 * */
export const workerPayInfo = async (params: any) => {
  return await request.get('rest/pay/pay-payment-record/worker-pay-info', {
    params: params
  })
}

/**
 * 账单详情—基本信息
 *
 * serveCompanyId
 * */
export const serverCompanyBillInfo = async (params: any) => {
  return await request.get('rest/pay/pay-payment-record/server-company-bill-info', {
    params: params
  })
}

/**
 * 账单详情—基本信息
 *
 * idCardNo
 * */
export const employeeAllSalary = async (params: any) => {
  return await request.get('rest/pay/pay-personal-paid/employee-all-salary', {
    params: params
  })
}


