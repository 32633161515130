import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/icon-male.png'
import _imports_1 from '@/assets/icon-female.png'


const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "head flex flex-ai-c" }
const _hoisted_3 = { class: "fs-16 bold flex flex-ai-c" }
const _hoisted_4 = {
  key: 0,
  src: _imports_0,
  alt: "",
  class: "icon-gender"
}
const _hoisted_5 = {
  key: 1,
  src: _imports_1,
  alt: "",
  class: "icon-gender"
}
const _hoisted_6 = { class: "body mg-t-20 flex gap-16" }
const _hoisted_7 = { class: "flex-1 flex bg-blue" }
const _hoisted_8 = { class: "name fc-lightgray" }
const _hoisted_9 = { class: "value fs-22 bold mg-t-10" }
const _hoisted_10 = { class: "bg-blue flex flex-column flex-jc-c" }
const _hoisted_11 = { class: "fc-black" }
const _hoisted_12 = { class: "mg-t-10" }
const _hoisted_13 = { class: "fc-black" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_card, { shadow: "never" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", null, _toDisplayString(_ctx.detail.userName), 1),
            (_ctx.detail.sex === 0)
              ? (_openBlock(), _createElementBlock("img", _hoisted_4))
              : _createCommentVNode("", true),
            (_ctx.detail.sex === 1)
              ? (_openBlock(), _createElementBlock("img", _hoisted_5))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listData, (i, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "flex-item flex-1 flex flex-column flex-jc-sb",
                key: index
              }, [
                _createElementVNode("div", _hoisted_8, _toDisplayString(i.name) + "：", 1),
                _createElementVNode("div", _hoisted_9, [
                  _cache[0] || (_cache[0] = _createElementVNode("span", { class: "fs-14" }, "¥", -1)),
                  _createTextVNode(_toDisplayString(i.value), 1)
                ])
              ]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", null, [
              _cache[1] || (_cache[1] = _createElementVNode("span", { class: "fc-lightgray" }, "电话号码：", -1)),
              _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.detail.phone), 1)
            ]),
            _createElementVNode("div", _hoisted_12, [
              _cache[2] || (_cache[2] = _createElementVNode("span", { class: "fc-lightgray" }, "身份证号：", -1)),
              _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.detail.idCardNo), 1)
            ])
          ])
        ])
      ]),
      _: 1
    })
  ]))
}