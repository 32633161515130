import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_infoBill = _resolveComponent("infoBill")!
  const _component_billDetail = _resolveComponent("billDetail")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_billSum = _resolveComponent("billSum")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_infoBill, {
      "serve-company-id": _ctx.serveCompanyId,
      "start-time": _ctx.startTime,
      "end-time": _ctx.endTime
    }, null, 8, ["serve-company-id", "start-time", "end-time"]),
    _createVNode(_component_el_card, {
      class: "mg-t-20",
      shadow: "never"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tabs, {
          modelValue: _ctx.activeName,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeName) = $event)),
          class: "demo-tabs",
          onTabClick: _ctx.handleClick
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_tab_pane, {
              label: "对账详单",
              name: "detail"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_billDetail, {
                  "serve-company-id": _ctx.serveCompanyId,
                  "start-time": _ctx.startTime,
                  "end-time": _ctx.endTime
                }, null, 8, ["serve-company-id", "start-time", "end-time"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_tab_pane, {
              label: "对账总和",
              name: "sum"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_billSum, {
                  "serve-company-id": _ctx.serveCompanyId,
                  "start-time": _ctx.startTime,
                  "end-time": _ctx.endTime
                }, null, 8, ["serve-company-id", "start-time", "end-time"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue", "onTabClick"])
      ]),
      _: 1
    })
  ]))
}