import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "border-top" }
const _hoisted_2 = { class: "title-box flex flex-ai-c flex-jc-sb" }
const _hoisted_3 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_searchComponent = _resolveComponent("searchComponent")!
  const _component_Download = _resolveComponent("Download")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_tableComponent = _resolveComponent("tableComponent")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_searchComponent, {
      flex: "",
      "search-list": _ctx.searchList,
      "onUpdate:searchList": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchList) = $event)),
      "button-list": _ctx.buttonList,
      onDoSearch: _ctx.handleSearch
    }, null, 8, ["search-list", "button-list", "onDoSearch"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "fs-16 bold" }, "预支列表", -1)),
        _createVNode(_component_el_button, {
          type: "primary",
          onClick: _ctx.handleExport
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_icon, null, {
              default: _withCtx(() => [
                _createVNode(_component_Download)
              ]),
              _: 1
            }),
            _cache[1] || (_cache[1] = _createTextVNode(" 导出明细 "))
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _createVNode(_component_tableComponent, {
        class: "mg-t-16",
        "table-data": _ctx.tableData,
        "table-head": _ctx.tableHead,
        "row-key": "id",
        onSelectionChange: _ctx.handleSelectionChange,
        "page-info": _ctx.pageInfo,
        onSizeChange: _ctx.handleSizeChange,
        onCurrentChange: _ctx.handleCurrentChange
      }, {
        operate: _withCtx((scope) => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_el_button, {
              type: "text",
              size: "small",
              onClick: _withModifiers(($event: any) => (_ctx.toDetail(scope.row)), ["stop"])
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("查看详情")
              ])),
              _: 2
            }, 1032, ["onClick"])
          ])
        ]),
        _: 1
      }, 8, ["table-data", "table-head", "onSelectionChange", "page-info", "onSizeChange", "onCurrentChange"])
    ])
  ]))
}