
import { defineComponent } from 'vue'
import axios from 'axios'

import { workerPayInfo } from '@/api/salary'

import searchComponent from '@/components/search-component/index.vue'
import tableComponent from '@/components/table-component/index.vue'
import {exportBillDetail} from "@/api/export";

export default defineComponent({
  components: {
    searchComponent,
    tableComponent
  },
  props: {
    serveCompanyId: {
      type: [String, Number],
      default: ''
    },
    startTime: {
      type: String,
      default: ''
    },
    endTime: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // 操作状态(0 未领取 1 已领取 2 停止发薪 3进行中 4领取失败)
      statusMap: {
        0: '未领取',
        1: '已领取',
        2: '停止发薪',
        3: '进行中',
        4: '领取失败'
      },
      searchList: {
        searchInfo: {
          name: '搜索',
          prop: 'searchInfo',
          type: 'input',
          placeholder: '请输入人员信息',
          value: '',
          defaultValue: ''
        }
      } as any,
      buttonList: [
        {
          name: '导出明细',
          buttonType: 'export',
          type: 'danger',
          fn: () => {
            this.onExport()
          }
        }
      ] as any,
      tableHead: [
        // {
        //   prop: 'index'
        // },
        {
          prop: 'userName',
          label: '姓名'
        },
        {
          prop: 'idCardNo',
          label: '身份证号',
          width: 170
        },
        {
          prop: 'receivedCount',
          label: '领薪次数'
        },
        {
          prop: 'totPay',
          label: '当前共发金额',
          prefix: '¥'
        },
        {
          prop: 'received',
          label: '当前共领金额',
          prefix: '¥'
        },
        {
          prop: 'unclaimed',
          label: '当前待领金额',
          prefix: '¥'
        },
        {
          prop: 'stopPay',
          label: '当前停发金额',
          prefix: '¥'
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ] as any,
      tableData: [] as any,
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any,
      selects: []
    }
  },
  watch: {
    serveCompanyId: {
      handler(newval) {
        if (newval) {
          this.getData()
        }
      },
      immediate: true
    }
  },
  methods: {
    handleSelectionChange(row: any) {
      this.selects = row.map((item: any) => item.id)
    },
    createQuery(hasPage = true) {
      const query = {} as any
      if (hasPage) {
        query.pageNum = this.pageInfo.pageNum
        query.pageSize = this.pageInfo.pageSize
      }
      for (const key in this.searchList) {
        if (this.searchList[key].type === 'dateRange') {
          query[`begin${this.searchList[key].prop}`] = this.searchList[key].value?.length ? this.searchList[key].value[0] : ''
          query[`end${this.searchList[key].prop}`] = this.searchList[key].value?.length ? this.searchList[key].value[1] : ''
        } else {
          query[(this.searchList[key]).prop] = this.searchList[key].value
        }
      }
      return query
    },
    handleSearch() {
      this.pageInfo.pageNum = 1
      this.getData()
    },
    handleSizeChange(ev: number) {
      this.pageInfo.pageSize = ev
      this.getData()
    },
    handleCurrentChange(ev: number) {
      this.pageInfo.pageNum = ev
      this.getData()
    },
    async getData() {
      const query = this.createQuery()
      query.serveCompanyId = (this as any).serveCompanyId
      query.beginPayTime = this.startTime
      query.endPayTime = this.endTime
      const res = await workerPayInfo(query) as any
      this.tableData = res.list || []
      this.pageInfo.total = res.total || 0
    },
    async onExport () {
      const result = await exportBillDetail({
        serveCompanyId: this.serveCompanyId as string,
        beginPayTime: this.startTime,
        endPayTime: this.endTime,
        ...this.createQuery(false)
      })
      window.location.href = process.env.VUE_APP_BASE_URL + '/common/download?delete=true&fileName=' + result

      this.$message.success('导出成功！')
    }
  }
})
